@import "variables";

.p-button .p-button-icon {
  margin-right: 8px;
}

.p-button {
  justify-content: center;
  white-space: nowrap;
  padding: 0 12px;
}

.p-button {
  border-radius: $default-border-radius;
}

button.ui-button {
  &.ui-button-sm {
    height: 32px;
  }
  &.ui-button-secondary {
    @extend .small-text-bold;
    color: $text-gray;
  }
  &.no-focus-mark:focus {
    box-shadow: none;
  }
}

button.p-button-text.is-gray {
  color: $dark-gray;
}
