.p-chips-multiple-container {
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
}

.p-chips-token {
  color: #565656;
  border-radius: 16px !important;
}

.p-chips-token:has(.invalid) {
  background: #fca6a6 !important;
}

.p-chips-input-token input::placeholder {
  color: #979797;
}

.p-chips:not(.db-multi-line-chips) {
  height: 38px !important;
}

.p-chips.db-multi-line-chips {
  display: flex;

  .p-chips-multiple-container {
    overflow: visible;
  }
}
