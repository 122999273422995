.db-ui-overlay-tooltip {
  background: $text-gray;
  color: $white;
  padding: 8px;

  &::before {
    display: none;
  }

  .db-ui-overlay-tooltip-line {
    @extend .small-text;
    @extend .text-white;
    display: flex;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
