@import "variables";

db-plan {
  db-dynamic-size-virtual-scroll,
  cdk-virtual-scroll-viewport {
    height: 100%;
    margin: 0;
    width: 93%;
    max-width: 830px;

    @include respond-to(xsmall) {
      width: 100%;
    }

    @include respond-to(small) {
      width: 100%;
    }

    @include respond-to(medium) {
      width: 100%;
    }
  }
}
