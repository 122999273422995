$dark-purple: #4b1548;
$purple: #700063;
$light-purple: #661560;
$lighter-purple: #7c3277;
$yellow: #e07800;
$black: #000000;
$white: #ffffff;

$db-purple: #7646ff;
$purplish-blue: #5b26f3;
$lighter-purple: #c8b5ff;
$lightest-purple: rgba(118, 70, 255, 0.15);
$secondary-office-purple: #916bff;
$secondary-unknown: #8f8f8f;
$lavender: #cdd7ff;
$dark-lavender-blue: #7a8dd6;
$magnolia: #f5f6fd;
$blue-chalk: #e9edfd;
$red-chalk: #fae0e0;
$pistachio: #6c9a60;
$warning-yellow: #fff3cc;

$new-white: #f5f6fe;
/*shades of grey in figma*/
$new-text: #595c79;
$new-title: #313346;
$new-blue: #5c639a;
$blue-gray: #93a1da;
$dark-blue-gray: #5c639a;

$red: #f81f20;
$light-red: #fae0e0;
$status-red: #ff5455;

$new-green: #09644b;
$status-green: #3da491;
$orange: #ffa800;
$orange-dark: #f98519;
$guest: #ac5606;

$availability-bad: #ff5455;
$availability-medium: #d67100;
$availability-good: $status-green;

$alert-red: #ffdcdc;
$eerie-black: #212121;
$space-cadet: #313346;
$davys-gray: #605e5e;
$dark-gray: #979797;
$new-dark-gray: #8f8f8f;
$text-gray: #565656;
$primary-gray: #e7e7e7;
$primary-black: #000000;
$onyx: #3d3d3d;
$jet: #353535;
$gray-web: #828282;
$middle-gray: #bcbcbc;
$jonquil: #d67100;
$quick-silver: #a5a5a5;
$light-grayish-blue: #f5f6fe;
$ghost-white-2: #f9f9fd;
$ghost-white: #f5f6fe;
$bluish-background: #f5f6fd;
$disabled-gray: #e0e0e0;
$dark-electric-blue: #617381;
$wild-blue-yonder: #a1a3bb;
$blue-bell: #9097cc;
$gainsboro: #a1a3bb;
$middle-purple: #d671c0;

$main-background: rgba(darken($white, 10%), 0.5);
$text-color: lighten($black, 50%);
$light-background-accent: rgba(lighten($purple, 40%), 0.1);
$blue-linear-gradient: linear-gradient(78.16deg, #e9edfd 1.39%, #f5f6fd 100%);

$border-color: lighten($black, 75%);
$logged-body-color: #f2f2f2; // This is the end result color of the different opaque element on top of the body when logged in
$light-gray: #f8f8f8;
$light-gray-background: #fafafa;

$row-flex-breakpoint: 1650px;
$main-container-min-width: 710px;
$header-height: calc(42px + 30px + 30px);
$sidebar-width: 224px;
$minimized-sidebar-width: 104px;
$border-divider: solid 1px $primary-gray;
$user-selector-width: 200px;
$tab-switch-margin-bottom: 14px;
$font-weight-bold: 500;
$font-size-20: 20px;
$font-size-16: 16px;
$font-size-14: 14px;
$font-size-12: 12px;
$font-size-10: 10px;
$font-size-11: 11px;
$default-box-shadow: 0 3px 10px rgba(32, 32, 32, 0.15);

/*Icons*/
$arrow-size-18: 18px;
$arrow-size-12: 12px;
$arrow-size-8: 8px;
$icon-size-12: 12px;
$icon-size-14: 14px;
$icon-size-16: 16px;
$icon-size-20: 20px;
$icon-size-24: 24px;
$icon-size-64: 64px;

/* Circle avatars */
$avatar-size-18: 18px;
$avatar-size-28: 28.5px;
$avatar-size-36: 36px;
$avatar-size-32: 32px; // 36 - 4px for border of 2px
$avatar-size-48: 48px;
$avatar-size-56: 56px;
$avatar-size-52: 52px; // 56 - 4px for border of 2px
$avatar-size-112: 112px;
$avatar-border: solid $white 2px;

/* Onboarding */
$onb-lavender-blue: #e4daff;

/* User profile slide over */
$user-profile-feed-container-width: 684px;

$default-border-radius: 8px;
$corner-border-radius: 4px;
$default-border: 1px solid #bcbcbc;
$border-outline-default: 1px solid $primary-gray;
$default-padding: 8px;
$purple-border-on-hover: 2px solid rgba(200, 181, 255, 0.5);
$button-hover-clr: #f6f6f6;
$active-entry-border-color: #c8b5ff80;

$font: "ProximaNova";
$font-bold: "ProximaNovaBold";

$button-height: 40px;

$kioskDark: #131313;
$kioskGray1: #4a4a4a;
$kioskGray2: #292929;
$kioskGray3: #2c2c2d;
$kioskGray4: #1c1c1f;
$kioskGray5: #777779;
$kioskGray6: #5e5a5ade;

$kioskAvailable: #2acfb2;
$kioskCheckIn: #e59a07;
$kioskOccupied: #fc7373;

$kioskAvailableOpaque: rgba(42, 176, 139, 0.4);
$kioskCheckInOpaque: rgba(229, 154, 7, 0.4);
$kioskOccupiedOpaque: rgba(252, 115, 115, 0.4);
$breakpoint-xsmall: 376px;
$breakpoint-small: 570px;
$breakpoint-medium: 620px;
$breakpoint-large: 992px;
$breakpoint-xlarge: 1200px;
$breakpoint-xxlarge: 1440px;

// Mixin for media queries
@mixin respond-to($breakpoint) {
  @if $breakpoint == xsmall {
    @media (max-width: $breakpoint-xsmall) {
      @content;
    }
  } @else if $breakpoint == small {
    @media (min-width: ($breakpoint-xsmall + 0.5)) and (max-width: $breakpoint-small) {
      @content;
    }
  } @else if $breakpoint == medium {
    @media (min-width: ($breakpoint-small + 0.5)) and (max-width: $breakpoint-medium) {
      @content;
    }
  } @else if $breakpoint == large {
    @media (min-width: ($breakpoint-medium + 0.5)) and (max-width: $breakpoint-large) {
      @content;
    }
  } @else if $breakpoint == xlarge {
    @media (min-width: ($breakpoint-large + 0.5)) {
      @content;
    }
  }
}
