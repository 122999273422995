p-steps {
  .p-steps-number,
  .p-steps-title {
    font-weight: bolder;
  }

  .dirty-white .p-steps-item .p-menuitem-link .p-steps-number {
    background-color: #f5f6fd;
  }
}
