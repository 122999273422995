/* Global style material related fixes */
@import "variables";

/* Spacing of card */
.mat-mdc-card {
  padding: 16px;

  .mat-mdc-card-content {
    padding: 0;
  }
}

/* Font of table */
.mat-mdc-row,
.mat-mdc-cell {
  font-family: $font;
}

/* Temporary fix until we replace buttons in edit floor */
.mode-button.mat-mdc-unelevated-button {
  width: 160px !important;
  font-family: $font !important;
  font-size: 14px !important;
  letter-spacing: normal !important;
  --mat-mdc-button-persistent-ripple-color: white !important;
}
