@import "variables";

db-date-picker-input {
  .p-calendar .p-datepicker {
    /*Prevent popup calendar being squashed */
    min-width: 360px;
  }

  /* Border around the calendar icon */
  .p-datepicker-trigger {
    border: $default-border;
    border-left: 1px solid transparent;
    background: transparent;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .icon-background-white .p-datepicker-trigger {
    /*Background of calendar icon */
    background-color: $white;
  }
}
