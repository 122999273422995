@import "variables";

db-select-button {
  .p-selectbutton {
    display: flex;
    flex-wrap: nowrap;
    height: $button-height;

    .p-button {
      padding: 0.75rem 1.15rem;
      border: none;

      &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 1px solid $blue-chalk;
      }

      &:focus {
        box-shadow: none;
      }

      &.p-highlight,
      &:hover {
        color: $db-purple;
        background-color: $white;
      }
    }
  }
}
