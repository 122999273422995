.analytics-filter-select-button-tooltip {
  display: inline-block;
  min-width: 185px !important;
}

.avatar-tooltip {
  display: flex;
  flex-wrap: nowrap;
  min-width: fit-content;
}

.hwp-rules-tooltip {
  max-width: unset !important;
  width: auto !important;
  .p-tooltip-text {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
