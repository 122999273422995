.extended-menu {
  &::before,
  &::after {
    display: none;
  }

  &.p-overlaypanel {
    width: 210px;
    max-height: 300px;
    overflow-y: auto;
    background: #ffffff;
    box-shadow: 5px 5px 5px #e0e0e066;
    padding: 24px 24px 28px 24px;
    border: 2px solid #e0e0e0;
    border-radius: 15px;
    margin-top: 0;
  }

  .p-overlaypanel-content {
    & > * {
      margin-bottom: 12px;
      display: block;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
