.user-icon::before {
  content: url("/assets/icons/user.svg");
}

.spaces-icon::before {
  content: "";
  background-image: url("/assets/icons/your-spaces.svg");
  display: block;
  background-size: contain;
}

.sign-out-icon::before {
  content: "";
  background-image: url("/assets/icons/sign-out.svg");
  display: block;
  background-size: contain;
}

.arrow-down::before {
  content: url("/assets/icons/arrow-down.svg");
}

.arrow-up::before {
  content: url("/assets/icons/arrow-up.svg");
}

.toggle-plus::before {
  content: url("/assets/icons/toggle-plus.svg");
}

.toggle-minus::before {
  content: url("/assets/icons/toggle-minus.svg");
}

.checkbox::before {
  content: url("/assets/icons/checkbox.svg");
}

.upload::before {
  content: url("/assets/icons/upload.svg");
}

.download::before {
  content: url("/assets/icons/download.svg");
}

.csv-file::before {
  content: url("/assets/icons/csv-file.svg");
}

.catering::before {
  content: url("/assets/icons/catering.svg");
}

.private-event::before {
  content: url("/assets/icons/eye-off-outline.svg");
}

.svg-email::before {
  content: url("/assets/icons/email.svg");
}

.green-check::before {
  content: url("/assets/icons/green-check.svg");
}

.svg-recent {
  content: url("/assets/icons/recent.svg");
}

.svg-dedicated-resource {
  content: url("/assets/icons/dedicated-resource.svg");
}

.svg-access-lock {
  content: url("/assets/icons/access-lock.svg");
}

.svg-heart {
  content: url("/assets/icons/fav-spaces/heart.svg");
}

.svg-heart-full {
  content: url("/assets/icons/fav-spaces/heart-full.svg");
}

.svg-heart-full-purple {
  content: url("/assets/icons/fav-spaces/heart-purple-full.svg");
}

.svg-neutral-toast {
  content: url("/assets/icons/neutral-toast.svg");
}

.svg-warning-sign {
  content: url("/assets/icons/warning-sign.svg");
}

.svg-delete-sign {
  content: url("/assets/icons/delete-sign.svg");
}

.svg-delete-sign-white {
  content: url("/assets/icons/delete-sign-white.svg");
}

.svg-success-checkmark {
  content: url("/assets/icons/success-checkmark.svg");
}

.red-info::before {
  content: url("/assets/icons/info-message-error.svg");
  width: 20px;
  height: 20px;
  display: inline-block;
}

.reload-white::before {
  content: url("/assets/icons/reload-white.svg");
  width: 20px;
  height: 20px;
  display: inline-block;
}

.red-triangle-info::before {
  content: url("/assets/icons/info-message-red.svg");
  width: 20px;
  height: 20px;
  display: inline-block;
}

.green-check-absolute::before {
  content: url("/assets/icons/green-check.svg");
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}

.upload-file::before {
  content: url("/assets/icons/upload-file.svg");
}

.warning-yellow-icon::before {
  content: url("/assets/icons/warning-yellow.svg");
  width: 10px;
  height: 10px;
  display: block;
  margin-right: 8px;
  position: relative;
  top: -6px;
}

.drag-indicator::before {
  content: url("/assets/icons/drag-indicator.svg");
}

.vertical-dash {
  margin-left: 8px;
  margin-right: 8px;
  height: 10px;
  border-left: 1px solid $text-gray;
}

i {
  &.size-14 {
    width: $icon-size-14;
    height: $icon-size-14;
  }
  &.size-16 {
    width: $icon-size-16;
    height: $icon-size-16;
  }
}

.icon-size-14 {
  width: $icon-size-14;
  height: $icon-size-14;
}

.icon-size-16 {
  width: $icon-size-16;
  height: $icon-size-16;
}

.icon-size-20 {
  width: $icon-size-20;
  height: $icon-size-20;
}

.icon-size-64 {
  width: $icon-size-64;
  height: $icon-size-64;
}
