.flex-center {
  display: flex;
  justify-content: center;
}

.flex-center-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-bg-paginator {
  .p-paginator {
    background-color: transparent;
  }
}

.no-border {
  .p-inputtext {
    border-color: transparent;
  }

  &.p-inputtext {
    border-color: transparent;
  }
}

.input-unset-border {
  border: unset;

  &.p-inputtext:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: unset;
    border-color: unset;
  }
}

.full-width-inputtext {
  .p-calendar {
    width: 100%;
  }
}

.db-input-error {
  color: #ff5455;
  margin-top: 4px;
}
