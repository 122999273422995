@font-face {
  font-family: ProximaNova;
  src: url("/assets/fonts/ProximaNova-Regular.otf") format("opentype");
}

@font-face {
  font-family: ProximaNovaBold;
  src: url("/assets/fonts/ProximaNova-Bold.otf") format("opentype");
}

@font-face {
  font-family: AtlasGrotesk;
  src: url("/assets/fonts/Atlas-Grotesk-Web-Light-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: AtlasGroteskBold;
  src: url("/assets/fonts/Atlas-Grotesk-Web-Bold-Regular.ttf")
    format("truetype");
}
