@use "@angular/material" as mat;
@import "deskbird-prime-theme.css";
@import "deskbird-prime-theme-overrides.css";
@import "material-design-icons/iconfont/material-icons.css";
@import "@angular/material/theming";
@import "overrides-material";
@import "color-palletes";
@import "variables";
@import "utils";
@import "overrides";
@import "font-faces";
@import "text";
@import "button";
@import "grid";
@import "radio";
@import "icons";
@import "modal";
@import "calendar";
@import "alert";
@import "tooltip";

@include mat.core();

.wf-loading body .material-icons {
  color: transparent;
}

$my-primary: mat-palette($new-purple-palette);
$my-accent: mat-palette($red-palette);
$my-warn: mat-palette($orange-palette);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
    typography: mat.define-typography-config(
        $body-1: mat.define-typography-level(
            $font-family: ProximaNova,
            $font-size: 14px,
            $line-height: 28px,
            $font-weight: 200,
          ),
        $headline-1: mat.define-typography-level(
            $font-family: ProximaNovaBold,
            $font-size: 32px,
            $line-height: 48px,
            $font-weight: 500,
          ),
        $subtitle-1: mat.define-typography-level(
            $font-family: ProximaNovaBold,
            $font-size: 20px,
            $line-height: 25px,
            $font-weight: 500,
          ),
        $subtitle-2: mat.define-typography-level(
            $font-family: ProximaNovaBold,
            $font-size: 21px,
            $line-height: 25px,
            $font-weight: 500,
          ),
      ),
  )
);

@include mat.all-component-themes($my-theme);

html,
body {
  padding: 0;
  margin: 0;
  font-size: 14px;
  min-width: 100%;
  font-family: ProximaNova;

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

a {
  color: $db-purple;
}

/* Having exact height set in theme breaks the size of the button when used in different containers */
.p-button.p-button-icon-only.p-button-rounded {
  height: unset;
}

/* Height of one tree node */
.mat-tree-node {
  min-height: 32px;
  //height: 32px !important;
}

/* Mat menu with no max-width */
.mat-menu-no-max-width {
  max-width: none !important;
}

/* Customize tooltip */
.tooltip-panel-gray {
  background: $text-gray;
  padding: 8px;

  /*Small text*/
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: $text-gray;
}

/*
  FIXME: there is an issue related to the virtual scroll viewport
  below CSS is a temporary solution
  https://github.com/angular/components/issues/26348
*/
db-user-search {
  .cdk-virtual-scroll-spacer {
    position: absolute;
    top: 0;
    left: 0;
  }
}

/* PrimeNG General theme override */
:root {
  --primary-color: #{$db-purple} !important;
  --secondary-color: #979797;
}

/* PrimeNG Radio button */
.p-radiobutton {
  height: 16px !important;
  width: 20px !important;

  & + label {
    color: $text-gray;
  }
}

.p-radiobutton + label {
  color: $text-gray;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.p-radiobutton .p-radiobutton-box {
  height: 16px !important;
  width: 16px !important;
  border-color: $middle-gray !important;

  &.p-highlight {
    background: $white !important;
    border-color: $db-purple !important;

    .p-radiobutton-icon {
      width: 8px !important;
      height: 8px !important;
      background-color: $db-purple;
    }

    &.p-focus {
      box-shadow: 0px 0px 0px 2.8px #e4daff !important;
    }
  }
}

.p-radiobutton-wrapper {
  display: flex;
  align-items: center;
  color: $text-gray;
}

.p-radiobutton-wrapper.vertical + .p-radiobutton-wrapper.vertical {
  margin-top: 12px;
}

.p-radiobutton-box.p-disabled {
  background-color: $primary-gray !important;
}

/* End of PrimeNG Radio button */

/* PrimeNG Checkbox */
.checkbox-wrapper {
  display: flex;
  align-items: center;
}

p-checkbox {
  width: 16px;
  height: 16px;

  & + label {
    color: $text-gray;
    margin-left: 9px;
    line-height: 24px;
  }
}

.p-checkbox-label {
  color: $text-gray;
  line-height: 24px;
}

.p-checkbox {
  height: 16px !important;
  width: 20px !important;

  & + label {
    color: $text-gray;
  }
}

.p-checkbox .p-checkbox-box {
  height: 16px !important;
  width: 16px !important;
  border-color: $middle-gray !important;
  border-radius: 2px !important;

  &.p-focus {
    box-shadow: 0px 0px 0px 2.8px #e4daff !important;
  }

  &.p-highlight {
    background: $db-purple !important;
    border-color: $db-purple !important;

    .p-checkbox-icon {
      font-size: 8px !important;
      font-weight: bold;
    }
  }

  &.p-disabled {
    background-color: $primary-gray;
  }
}

/* End of PrimeNG Checkbox */

/* PrimeNG Slide toggler */
.slide-toggle-wrapper {
  display: flex;
  align-items: center;
}

.slide-toggle-wrapper + .slide-toggle-wrapper {
  margin-top: 8px;
}

.p-inputswitch .p-inputswitch-slider {
  width: 42px !important;
  height: 24.5px !important;

  &::before {
    width: 17.5px !important;
    height: 17.5px !important;
    margin-top: -8.75px !important;
  }
}

.p-inputswitch .p-inputswitch-slider {
  background-color: $secondary-unknown;

  &:hover {
    background-color: $middle-gray;
  }
}

.p-inputswitch {
  &.p-focus .p-inputswitch-slider {
    box-shadow: 0px 0px 0px 2.8px #e4daff !important;
  }
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: $db-purple !important;

  &:hover {
    background: $purplish-blue !important;
  }
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(16.5px) !important;
}

p-inputswitch {
  height: 24.5px;

  & + label {
    color: $text-gray;
    margin-left: 9px;
  }
}

/* End of PrimeNG Slide toggler */

/* PrimeNG Dropdown */

.p-dropdown-panel .p-dropdown-items {
  // remove padding on top and bottom of items list
  padding: 0 !important;
}

/* End of PrimeNG Dropdown */

.p-virtualscroller-content {
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(32, 32, 32, 0.15);
  overflow: hidden;
}

.big-link {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #ffffff;
  font-weight: $font-weight-bold;
  cursor: pointer;
  font-family: ProximaNovaBold;
  display: block;
  position: absolute;
  width: 100%;
  bottom: -70px;
  left: 0;
  text-decoration: none;
}

.card-above-title {
  padding-left: 1.25rem;
  margin-bottom: 8px;
}

button.db-secondary-button.p-button {
  color: #979797;
}

p-button.db-back-button .p-button.p-button-text {
  color: #000;
}

.field label {
  font-weight: $font-weight-bold;
}

.ui-form-row {
  margin-bottom: 16px;
}

.ui-card {
  display: block;
  margin-bottom: 32px;

  .p-card {
    box-shadow: none;
  }
  .p-card-body {
    padding: 16px;
  }
  h4:first-of-type {
    margin-bottom: 16px;
  }
}

button.main-action {
  width: 161px;
  justify-content: center;
  margin-left: 10px;
}

.sort-direction {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #c0c0c0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
  img {
    width: 25px;
  }
}

/* Hide overlay panel arrow and show panel right after opening element */
.p-overlaypanel.panel-without-arrow {
  margin-top: 0px !important;
}

.p-overlaypanel.panel-without-arrow:after,
.p-overlaypanel.panel-without-arrow:before {
  display: none;
}

/* end of Hide overlay panel arrow and show panel right after opening element */

strong {
  font-family: $font-bold;
  font-weight: $font-weight-bold;
}

.p-panel {
  border-bottom: 1px solid $primary-gray;
}

.p-panel-header {
  background-color: transparent !important;
  border-radius: 0 !important;
  border: 0 !important;
  padding: 16px 0 !important;
}

.p-panel-content {
  border-radius: 0 !important;
  border: 0 !important;
  padding: 16px 0 !important;
}

.flat-card-container {
  border-radius: $default-border-radius;
  background-color: $white;
  padding: 16px;
}
