.ui-advanced-radio-options {
  display: flex;
  flex-direction: column;

  .ui-advanced-radio-option {
    display: flex;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid var(--secondary-color);
    cursor: pointer;

    &.active {
      border: 1px solid var(--primary-color);
    }

    & + .ui-advanced-radio-option {
      margin-top: 10px;
    }

    .right-content {
      padding-left: 8px;
      flex: 1;

      .title {
        font-weight: 700;
        margin-bottom: 4px;
      }

      db-dropdown-with-search {
        display: block;
        width: 100%;
      }

      .field-checkbox {
        margin-top: 8px;
      }
    }
  }
}
