@import "variables";

.rounded {
  border-radius: $default-border-radius;
}

.border-gray {
  border: 1px solid $primary-gray;
}

.rounded-top {
  border-radius: $default-border-radius $default-border-radius 0 0;
}
