@import "variables";

db-time-picker-control .time-setter {
  &.am-pm {
    .p-dropdown.db-time-picker {
      width: 130px;
    }

    .p-dropdown.db-time-picker-as-input {
      width: 85px;
    }
  }

  &:not(.am-pm) {
    .p-dropdown.db-time-picker {
      width: 110px;
    }

    .p-dropdown.db-time-picker-as-input {
      width: 65px;
    }
  }

  .p-dropdown.db-time-picker {
    .p-inputtext.p-dropdown-label {
      height: 36px;
      text-align: center;
    }
  }

  .p-dropdown.db-time-picker-as-input {
    border: unset;

    .p-inputtext.p-dropdown-label {
      text-align: center;
      padding: 0.5rem 0.5rem;
      background: $magnolia;
    }

    &.p-element {
      border: unset;
    }

    .p-dropdown-trigger {
      // hide dropdown arrow
      display: none;
    }
  }
}

db-time-picker-control {
  label {
    display: block;
    margin-bottom: 4px;
  }
}
