@import "variables";

.ui-row {
  display: flex;
  flex-direction: row;
}

.ui-col {
  flex: 1;
}

.ui-col-content {
  padding: 16px;
}

.content-default {
  padding: $default-padding * 2;
}

.content-small {
  padding: $default-padding $default-padding * 2;
}

.content-medium {
  padding: $default-padding * 4 $default-padding * 3;
}
