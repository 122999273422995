@import "variables";
@import "theme/text";

.p-inline-message {
  padding: 12px 16px;
  width: 100%;
  justify-content: flex-start !important;

  .p-inline-message-text {
    color: $text-gray !important;
    font-size: 14px !important;
    @extend .normal-text;
  }
}

p-message.no-icon .p-inline-message-icon {
  display: none;
}

p-message:not(.no-icon) .p-inline-message-icon {
  flex-shrink: 0;
  width: 17px;
  height: 17px;
  margin-right: 16px !important;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: unset !important;

  &::before {
    content: none !important;
  }
}
