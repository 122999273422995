@import "variables";
@import "theme/text";

.p-tag {
  padding: 0 8px;
  border-radius: 4px;
}

.p-tag.magnolia {
  background: $magnolia !important;
  color: $text-gray !important;
}

.p-tag.gray {
  background: $primary-gray !important;
  color: $text-gray !important;
}

.p-tag.blue-chalk {
  background: $blue-chalk !important;
  color: $text-gray !important;
}

.p-tag.normal-text {
  @extend .normal-text;
}
