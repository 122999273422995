.no-border {
  .p-dropdown {
    border-color: transparent;
  }
}

.dropdown-width-150 {
  .p-dropdown {
    width: 150px;
  }
  .p-dropdown-panel {
    width: 150px;
  }
}

.dropdown-width-200 {
  .p-dropdown {
    width: 200px;
  }
  .p-dropdown-panel {
    width: 200px;
  }
}

.dropdown-width-250 {
  .p-dropdown {
    width: 250px;
  }
}

.dropdown-width-380 {
  .p-dropdown {
    width: 380px;
  }
}

.dropdown-panel-item-no-top-bottom-padding.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item {
  padding-top: 0;
  padding-bottom: 0;
}

.dropdown-panel-width-350.p-dropdown-panel {
  width: 350px;

  .p-dropdown-items .p-dropdown-item {
    display: flex;

    span {
      max-width: 350px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
    }
  }
}

.dropdown-panel-width-450.p-dropdown-panel {
  width: 450px;

  .p-dropdown-items .p-dropdown-item {
    display: flex;

    span {
      max-width: 450px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
    }
  }
}

.full-width-dropdown {
  .p-dropdown {
    width: 100%;
  }
}
