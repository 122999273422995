@import "variables";

.min-w-1200 {
  min-width: 1200px;
}

.p-datatable-wrapper {
  min-height: 500px;
}

.p-paginator-bottom {
  display: flex !important;
  justify-content: flex-end !important;
  background: none !important;
}

.p-datatable-table {
  border-spacing: 0 4px !important;

  tbody tr:not(.not-hoverable):hover {
    outline: 2px solid $lighter-purple;
    outline-offset: -2px;
    border-radius: 8px;
    cursor: pointer;
  }

  tr:first-child th:first-child {
    border-top-left-radius: 8px !important;
  }

  tr:nth-child(2) th:first-child {
    border-bottom-left-radius: 8px !important;
  }

  tr:nth-child(2) th:last-child {
    border-bottom-right-radius: 8px !important;
  }

  tbody {
    position: relative;
  }

  tr td:first-child {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }

  tr:first-child th:last-child {
    border-top-right-radius: 8px !important;
  }
  tr td:last-child {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;

    &.db-table-action-col {
      display: flex;
      justify-content: flex-end;
    }
  }

  th {
    background: $blue-chalk !important;
    border: none !important;
    color: $black !important;
  }

  td {
    border: none !important;
  }

  th,
  td {
    padding-left: 16px !important;
    &:not(:last-child) {
      padding-right: 0px !important;
    }

    &:last-child {
      padding-right: 16px !important;
    }
  }

  th.db-table-drag-col {
    width: 32px; // 16 + 16 padding = 32
    min-width: 32px;
  }
  td.db-table-drag-col {
    width: 32px;
    min-width: 32px;
    span.drag-indicator {
      margin-right: 8px;
      padding-top: 4px;
      cursor: ns-resize;

      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.3;
      }
    }
  }

  .db-table-check-col {
    width: 36px;
    min-width: 36px;
  }

  .db-arrow-col {
    width: 40px;
    min-width: 40px;

    padding-left: 0px !important;

    &.right {
      padding-left: 32px !important;
    }

    db-button-icon {
      button {
        width: 10px !important;
      }
    }
  }

  .db-title-col {
    width: 160px;
    min-width: 160px;

    padding-bottom: 12px !important;
  }

  .p-column-filter .p-component {
    border-color: transparent !important;
  }
}

.p-datatable-table:not(.db-ignore-clip-row) {
  tbody tr,
  thead tr:only-child {
    clip-path: xywh(0 0 100% 100% round 0.5em); // border radius fix
    transform: translateZ(0); // safari clip-path bug fix
  }
}

.p-datatable-table.db-table-no-filter {
  th.db-table-drag-col {
    width: 32px;
    min-width: 32px;
  }

  tr:first-child th:first-child {
    border-bottom-left-radius: 8px !important;
  }

  tr:nth-child(1) th:last-child {
    border-bottom-right-radius: 8px !important;
  }

  .p-datatable-thead > tr:first-child > th:not(.db-table-drag-drop) {
    padding-left: 16px !important;
    padding-top: 19px !important;
    padding-bottom: 19px !important;
  }
}

.p-datatable-table.db-table-border-collapse {
  border-collapse: collapse;
}

.p-datatable-table.db-table-no-min-height {
  .p-datatable-wrapper {
    min-height: 0;
  }
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: $text-gray !important;
}

#users-table {
  .p-datatable .p-datatable-tbody > tr > td:first-child {
    width: 300px;
  }
}

#users-table.row-size-64 {
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 4px 8px;
    height: 64px;
  }
}

#daily-office-table {
  .p-datatable-wrapper {
    /* longest empty state message should fit inside */
    min-height: 600px;
  }
}

#subscriptions-table {
  .p-datatable-wrapper {
    min-height: 0px;
  }
}

db-table.layout-fixed {
  table {
    table-layout: fixed;
  }
}

db-table {
  .p-datatable .p-datatable-thead > tr:first-child > th {
    padding-bottom: 6px !important;
  }

  thead {
    tr:nth-child(2) {
      th {
        padding-top: 0px !important;
      }

      position: relative;
      top: -4px;
    }
  }

  .ui-datatable .ui-datatable-data {
    min-height: 64px !important;
    max-height: 64px !important;
  }
}

.p-paginator {
  color: $text-gray;
  padding-right: 0px !important;

  .p-paginator-pages .p-paginator-page.p-highlight {
    background: $blue-chalk;
  }

  .p-paginator-rpp-options.p-dropdown.p-component {
    margin-right: 0px !important;
    border-color: transparent !important;
  }

  .p-link:disabled {
    color: $primary-gray;
  }

  .p-paginator-current,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-first,
  .p-paginator-last,
  .p-paginator-pages .p-paginator-page {
    color: $text-gray;
  }

  .p-paginator-current {
    cursor: default;
  }

  .p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-link:not(.p-highlight):hover {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 2px $onb-lavender-blue;
  }
}

.pi-sort-amount-down:before {
  content: url("/assets/icons/sort-amount-down.svg") !important;
}

.cell-content-max-3-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

db-table.overflow-visible .p-datatable > .p-datatable-wrapper {
  overflow: visible;
}

db-table.db-table-sticky-header {
  overflow: visible;

  p-table,
  .p-datatable,
  .p-datatable-table,
  .p-datatable-wrapper {
    overflow: visible;
  }

  .p-datatable-table .p-datatable-thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
  }
}
