.table-action-spinner {
  padding: 12px;
  height: 40px;
}

p-progressspinner .spinner-sm {
  height: 27px;
}

.prime-ng-diameter .p-progress-spinner {
  width: var(--primeng-progress-spinner-diameter) !important;
  height: var(--primeng-progress-spinner-diameter) !important;
}

.custom-color-dash .p-progress-spinner-circle {
  animation: custom-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-progress-spinner-color {
  from,
  to {
    stroke: var(--primeng-progress-spinner-dash-color);
  }
}
