@import "border";
@import "grid";
@import "variables";
@import "utils";

.ui-alert {
  padding: 12px 16px;
  border-radius: 8px;
}

.ui-alert-info {
  background-color: #f5f6fd;
}

.ui-alert-action {
  @extend .rounded;
  @extend .content-default;
  background-color: $blue-chalk;
  color: $text-gray;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .buttons {
    display: flex;
    gap: 16px;
  }
}
