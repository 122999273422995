@import "variables";

db-button-selector {
  .p-selectbutton {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .p-button {
      flex: 1 1 0;
      font-family: $font-bold;
      min-width: 40px;
      padding: 0.75rem 1.25rem;
      border-radius: 8px !important;
      border: 1px solid $middle-gray !important;
      &:not(:last-child) {
        margin-right: 16px !important;
      }
      &:hover {
        border-color: $db-purple !important;
      }
      &:focus {
        box-shadow: none;
      }
      &.p-disabled {
        background-color: $light-gray !important;
        color: $middle-gray !important;
      }
      &.p-highlight {
        background-color: $lighter-purple !important;
        border-color: $lighter-purple !important;
        color: $black !important;
        &.p-disabled {
          background-color: $lightest-purple !important;
          border-color: transparent !important;
          color: $new-dark-gray !important;
        }
      }
    }
  }
}
