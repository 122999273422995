@tailwind base;
@tailwind components;
@tailwind utilities;
/* You can add global styles to this file, and also import other style files */
@import "variables";
@import "../../../overrides";

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  #content {
    /*Remove space for side menu*/
    padding-left: 0px !important;
  }
}

.cdk-overlay-pane.bottom .card {
  &::before {
    content: "";
    top: -9px !important;
    margin-left: -10px;
    margin-top: 0 !important;
    left: 50% !important;
    border: solid 10px transparent !important;
    border-bottom-color: $primary-gray !important;
    z-index: 1;
  }

  &::after {
    top: -8px !important;
    margin-left: -10px;
    margin-top: 0 !important;
    left: 50% !important;
    border: solid 10px transparent !important;
    border-bottom-color: #fff !important;
  }
}

body.with-notice {
  margin-top: 36px;
}

body {
  --cc-btn-primary-bg: #7646ff;
  --cc-btn-secondary-bg: white;
  --cc-btn-secondary-border-color: #7646ff;
  --cc-btn-secondary-color: #7646ff;
  --cc-toggle-on-bg: #7646ff;
  --cc-btn-primary-border-color: #7646ff;
  --cc-toggle-off-bg: #e7e7e7;
  --cc-toggle-readonly-bg: #5d26f397;
  --cc-btn-primary-hover-bg: #5b26f3;
  --cc-btn-primary-hover-border-color: #5b26f3;
  --cc-btn-secondary-hover-bg: #eeeeee;
  --cc-btn-secondary-hover-color: #7646ff;
  --cc-btn-secondary-hover-border-color: #7646ff;
}

#cc-main .cm--wide .cm__btn-group--uneven {
  flex-direction: column;
  gap: 8px;
}

#cc-main
  .pm__section--expandable
  .pm__section-title-wrapper
  .pm__section-arrow {
  background-color: #e4e4e4;
}

#cc-main .toggle__icon-off {
  display: none;
}

#cc-main .toggle__icon-on {
  display: none;
}

#cc-main
  .pm__section--expandable
  .pm__section-title-wrapper
  .pm__section-title {
  cursor: pointer;
  padding-left: 3.4em;
}

#cc-main .pm__footer {
  flex-direction: column;
  gap: 8px;
}

#cc-main .pm__header .pm__close-btn {
  border: none;
}

#cc-main .cm--wide .cm__btn + .cm__btn,
#cc-main .cm--wide .cm__btn-group + .cm__btn-group {
  margin-left: 0 !important;
}

#cc-main .pm__title {
  user-select: none;
}

#cc-main .cm__desc {
  user-select: none;
}

#cc-main .cm__title {
  user-select: none;
}

#cc-main .pm__btn + .pm__btn,
#cc-main .pm__btn-group + .pm__btn-group {
  margin-left: 0 !important;
}

.mat-mdc-menu-panel.your-spaces-actions {
  min-height: 0px;
  box-shadow: 0px 3px 10px rgba(32, 32, 32, 0.15);
  border-radius: 8px;
  .mat-mdc-menu-content {
    padding: 0;
    .menu-action {
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0px 16px;
      color: $text-gray;
      font-size: 14px;
      border-bottom: 1px solid $primary-gray;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }

      &.disabled {
        cursor: not-allowed;
        background: rgba($primary-gray, 0.6);
      }
    }
  }
}
