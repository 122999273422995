@import "variables";

.calendar-with-icons {
  .p-datepicker {
    border: transparent;

    /* Emoji is overflowing and has to be visible */
    td > span {
      overflow: visible;
    }
  }

  .p-datepicker table td > span.p-highlight {
    background-color: $blue-chalk;
    color: $black;
  }

  .p-datepicker table th {
    font-family: $font-bold;
    font-weight: $font-weight-bold;
  }
}

.calendar-without-weekends {
  .p-datepicker-inline {
    width: 352px;

    @include respond-to(xsmall) {
      display: none;
    }

    @include respond-to(small) {
      display: none;
    }

    @include respond-to(medium) {
      display: 340px;
    }
  }

  table.p-datepicker-calendar tr {
    td,
    th {
      &:nth-child(6),
      &:nth-child(7) {
        display: none;
      }
    }
  }
}

.p-datepicker:not(.p-disabled)
  table
  td
  span.day-event-icon:not(.p-highlight):not(.p-disabled):hover {
  background-color: transparent;
}
