@import "variables";

/* Apply p-disabled styles to span.p-disabled which is used in the template of the calendar*/
/* The prime default theme has styles for span.p-disabled while in our component we need same for child span.p-disabled of span */

.p-datepicker:not(.p-disabled)
  table
  td
  span:not(.p-highlight):not(.p-disabled):not(:has(.p-disabled))
  :hover {
  background: $db-purple;
  color: $magnolia;
  border-radius: 50%;
}
.p-datepicker
  table
  td
  span:not(.p-highlight):not(.p-disabled):has(.p-disabled) {
  cursor: default !important;
  opacity: 0.6;
}
