administration .settings company-dropdown {
  .p-dropdown {
    width: 400px;
  }

  .p-dropdown-items-wrapper {
    max-width: 400px;
    min-width: 400px;
  }
}
