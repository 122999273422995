@import "variables";

h1 {
  font-family: $font-bold;
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 60px;
  line-height: 72px;
}

h2 {
  font-family: $font-bold;
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 45px;
  line-height: 45px;
}

h3 {
  font-family: $font-bold;
  font-size: 30px;
  font-weight: $font-weight-bold;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: $black;
}

h4 {
  /* Web/Heading 3 */
  font-family: $font-bold;
  font-size: 20px;
  font-weight: $font-weight-bold;
  line-height: 25px;
  letter-spacing: 0;
  text-align: left;
  margin: 0;
  color: $black;
}

.bold {
  font-weight: $font-weight-bold;
  font-family: $font-bold;
}

.normal-text {
  font-family: $font;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}

.normal-text-bold {
  font-family: $font-bold;
  font-size: 16px;
  font-weight: $font-weight-bold;
  line-height: 20px;
  letter-spacing: 0em;
  color: $text-gray;
}

.super-small-text {
  font-family: $font;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  color: $text-gray;
}

.small-text {
  font-family: $font;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: $text-gray;

  &.dark-gray {
    color: $dark-gray;
  }

  a {
    text-decoration: none;
  }
}

.small-text-bold {
  font-family: $font-bold;
  font-size: 14px;
  font-weight: $font-weight-bold;
  line-height: 18px;
  color: $text-gray;
}

.very-small-text,
.web-small {
  font-family: $font;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: $text-gray;
}

.very-small-text-bold {
  font-family: $font-bold;
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 12px;
  line-height: 15px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-primary {
  color: $db-purple;
}

.text-gray {
  color: $text-gray;
}

.text-middle-gray {
  color: $middle-gray;
}

.text-black {
  color: $black;
}

.text-danger {
  color: $red;
}

.text-success {
  color: $availability-good;
}

.text-primary {
  color: $db-purple;
}

.text-white {
  color: $white;
}

.text-black {
  color: $black;
}

.text-dark-gray,
.text-secondary {
  color: $dark-gray;
}

.text-no-wrap {
  white-space: nowrap;
}

.truncate-text {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.lowercase {
  text-transform: lowercase;
}
