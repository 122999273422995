p-colorpicker {
  display: block;
  width: 100%;
  height: 100%;
}

.p-colorpicker-preview {
  width: 100%;
  height: 100%;
}
